import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import { Field, CccisdCheckboxInput } from 'cccisd-formik';
import style from './style.css';

const Register = () => {
    const props = {
        loginProps: {},
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
            renderAllFields: ({
                formikBag,
                defaultFields: { title, firstName, lastName, email, password, repeatPassword, submitButton },
            }) => {
                return (
                    <>
                        {title}
                        {firstName}
                        {lastName}
                        {email}
                        {password} {repeatPassword}
                        <Field
                            name="termsandconds"
                            label="Acepto la política de privacidad de datos y las condiciones de uso."
                            component={CccisdCheckboxInput}
                            labelNotBold
                        />
                        {submitButton(formikBag)}
                        <div className={style.termsAndCondsLinks}>
                            <a href="https://www.3cisd.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                                Política de Privacidad de Datos
                            </a>
                            <a href="http://www.3cisd.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                                Condiciones de Uso
                            </a>
                        </div>
                    </>
                );
            },
        },
    };

    return <RegisterLoginPage {...props} />;
};

export default Register;
