import React from 'react';
import _find from 'lodash/find';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'cccisd-react-router';
import logo3c from './logo_3c.png';
import logoEsdm from './logo_esdm.jpg';
import logoFundacion from './logo_fundacion.jpg';
import logoIes from './logo_ies.png';
import logoInstitut from './logo_institut.jpg';
import logoUcdavis from './logo_ucdavis.png';
import style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={'row ' + style.wrapper}>
            {/* Login Form should be on top in mobile, but on right side in desktop */}
            <div className={'col-md-4 ' + style.mobileForm}>
                {Fortress.auth() ? (
                    <div className={style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute.url}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <LoginForm showPasswordToggle />
                )}
            </div>

            <div className="col-md-7">
                <h2>Le damos la bienvenida a Help is in Your Hands</h2>
                <div className={style.newInfo}>
                    <img src={logoInstitut} alt="Institut Tea Care" className={style.logoInstitut} />
                    <p>
                        Esta versión de HIIYH en español ha sido posible gracias al proyecto{' '}
                        <b>TEA CARE Mas Casadevall</b> que cuenta con la colaboración de la Fundació Autisme Mas
                        Casadevall, el Hospital Sant Joan de Déu Barcelona y el Instituto MIND de la Universidad de
                        California, Davis.
                    </p>
                    <ul className="list-unstyled">
                        <li>
                            <b>Edición y adaptación:</b> María Díez-Juan y Aritz Aranbarri
                        </li>
                        <li>
                            <b>Doblaje y revisión:</b> Beatriz Daudén y Judith Decathalogne
                        </li>
                        <li>
                            <b>Traducción:</b> Isabel Hoyos Seijo
                        </li>
                        <li className={style.logoFundacion}>
                            <b>Con el apoyo de:</b> <img src={logoFundacion} alt="Funcacion la Caixa" />
                        </li>
                    </ul>
                </div>
                <div className={style.logos}>
                    <img src={logoEsdm} alt="ESDM" style={{ minWidth: 60, maxWidth: 80 }} />
                    <img
                        src={logoUcdavis}
                        alt="UC Davis Health | Mind Institute"
                        style={{ minWidth: 160, maxWidth: 200 }}
                    />
                    <img src={logoIes} alt="Institute of Education Sciencs" style={{ minWidth: 140, maxWidth: 180 }} />
                    <img src={logo3c} alt="3C Institute" style={{ minWidth: 80, maxWidth: 100 }} />
                </div>
                <div className={style.originalInfo}>
                    <p>
                        <b>Help Is In Your Hands</b> es un producto que pertenece al Proyecto C-ESDM (Modelo Denver de
                        Atención Temprana de aplicación Comunitaria) desarrollado por Sally Rogers y Aubyn Stahmer.
                    </p>

                    <p>
                        Con el apoyo del Instituto de las Ciencias de la Educación (IES), el Instituto MIND y el
                        Instituto 3C.
                    </p>

                    <p>
                        En colaboración con los investigadores y los agentes comunitarios de la Universidad de
                        Pennsylvania, el Hospital Infantil de Colorado y la Universidad de Alabama.
                    </p>

                    <p>
                        La investigación llevaba a cabo en este proyecto está financiada por el Instituto de las
                        Ciencias de la Educación (IES), Departamento de Educación de EEUU beca #R324A150211, a través de
                        la Universidad de California, Davis.
                    </p>

                    <p>
                        Las opiniones expresadas en este producto pertenecen a las autoras y no representan la visión
                        del Instituto IES ni del Departamento de Educación de EEUU.
                    </p>
                </div>
            </div>
            <div className="col-md-1" />
            <div className={'col-md-4 ' + style.desktopForm}>
                {Fortress.auth() ? (
                    <div className={style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute.url}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <>
                        <LoginForm showPasswordToggle />
                        <div className={style.createNewAccount}>
                            <Link to="/login">¿Necesitas una cuenta?</Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Welcome;
